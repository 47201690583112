<script>
  import { fade } from "svelte/transition";
</script>

<div class="container mx-auto">
  <form class="w-full max-w-lg mx-auto mt-20 mb-20" id="contact-form">
    <div class="flex flex-wrap mb-6 mx-3">
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <label
          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-first-name">First Name</label
        ><input
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-3"
          type="text"
          placeholder="Jane"
          name="name"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <label
          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-last-name">Last Name</label
        ><input
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-3"
          type="text"
          placeholder="Doe"
          name="lastName"
        />
      </div>
    </div>
    <div class="flex flex-wrap mb-6 mx-3">
      <div class="w-full px-3">
        <label
          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-password">E-mail</label
        ><input
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          type="email"
          name="email"
          placeholder="jane@google.com"
        />
      </div>
    </div>
    <div class="flex flex-wrap mb-3 mx-3">
      <div class="w-full px-3">
        <label
          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-password">Message</label
        ><textarea
          class=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
          name="message"
        />
      </div>
    </div>
    <div class="md:flex md:items-center ">
      <div class="md:w-1/3 pl-6">
        <button
          class="shadow bg-teal-400 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-gray-700 font-bold py-2 px-4 rounded"
          type="submit">send</button
        >
      </div>
      <div class="md:w-2/3" />
    </div>
  </form>
</div>

<img
  src="/assets/images/misc/leaf.svg"
  alt=""
  class="w-64 img__sticky--br "
  in:fade={{ duration: 1500 }}
/>

<style>
  .img__sticky--br {
    position: absolute;
    right: 40px;
    bottom: 20px;
  }
</style>
