<script>
  import Nav from "./components/layout/Nav.svelte";
  import { Router, Route } from "svelte-routing";
  import Home from "./pages/Home/Home.svelte";
  import About from "./pages/About/About.svelte";
  import Articles from "./pages/Articles/Articles.svelte";
  import Companies from "./pages/Companies/Companies.svelte";
  import Contact from "./pages/Contact/Contact.svelte";

  // Articles: 2022
  import DecisionMakingHeuristics2022 from "./pages/Articles/2022/decision-making-heuristics.svelte";

  // Articles: 2020
  import EconomicsOpenSource2020 from "./pages/Articles/2020/the-economics-of-open-source.svelte";
  import ApplicationsMachineLearning2020 from "./pages/Articles/2020/applications-of-machine-learning.svelte";
  import MonolithsToMicroservices2020 from "./pages/Articles/2020/monoliths-to-microservices.svelte";

  // Articles: 2019
  import SeoRankGoogle2019 from "./pages/Articles/2019/seo-tips-and-tricks-to-rank-in-google.svelte";
  import ProfessionalDevelopment2019 from "./pages/Articles/2019/professional-web-development.svelte";

  export let url = ""; //This property is necessary declare to avoid ignore the Router
</script>

<Nav />
<Router {url}>
  <Route path="about"><About /></Route>
  <Route path="companies"><Companies /></Route>
  <Route path="contact"><Contact /></Route>
  <Route path="articles"><Articles /></Route>

  <Route path="articles/decision-making-heuristics"
    ><DecisionMakingHeuristics2022 /></Route
  >

  <Route path="articles/the-economics-of-open-source-software"
    ><EconomicsOpenSource2020 /></Route
  >

  <Route path="articles/applications-of-machine-learning"
    ><ApplicationsMachineLearning2020 /></Route
  >
  <Route path="articles/monoliths-to-microservices">
    <MonolithsToMicroservices2020 />
  </Route>

  <Route path="articles/seo-tips-and-tricks-how-to-rank-in-google">
    <SeoRankGoogle2019 />
  </Route>

  <Route
    path="articles/the-benefits-of-working-with-a-professional-web-developer"
  >
    <ProfessionalDevelopment2019 />
  </Route>

  <Route path="/"><Home /></Route>
</Router>
