<script>
  import { fade } from "svelte/transition";
</script>

<div class=" mx-auto container">
  <div class="flex flex-wrap -mx-2">
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/hpe.svg"
          alt="comprehensive business solutions"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/prg.png"
          alt="Construction management and plant damage recovery"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/luma.svg"
          alt="Visual fx studio"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/lei-duke.svg"
          alt="Clothing and lifestyle brand"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/homequest.svg"
          alt="Homequest Real Estate Broker - Flat fee 100% logo"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/ici-logo.svg"
          alt="Intellicom Business Phone Service Provider logo"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/r-life.svg"
          alt="Rlife funeral insurance company logo"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/trendboost.svg"
          alt="Trendboost instagram marketing company logo"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/peter-nguyen-studio.svg"
          alt="Peter nguyen famous wedding photographer logo"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/lamoi.svg"
          alt="Lamoi custom wedding dress designer logo"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/california-modern-home-styles.svg"
          alt="California modern home styles - home remodel company  "
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/clearstart.svg"
          alt="Clear start marketing and digital agency logo "
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/la-pix.svg"
          alt="Photobooth company"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/p-and-t.svg"
          alt="Auto Service Company"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/the-code-wizards.svg"
          alt="Tech news"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
    <div class="w-full lg:w-1/3 px-2 mt-8">
      <div class="h-48 pt-20">
        <img
          src="/assets/images/companies/two-palms.svg"
          alt="Clothing store"
          class=" h-10 pointer m-auto hover-reveal-color max-w-48"
          in:fade={{ duration: 1500 }}
        />
      </div>
    </div>
  </div>
</div>

<style>
  .hover-reveal-color {
    filter: grayscale(100%);
  }
  .hover-reveal-color:hover {
    filter: none;
  }
  .max-w-48 {
    max-width: 10rem;
  }
</style>
