<script>
	import { fade, fly } from "svelte/transition";
	import { navigate } from "svelte-routing";

	let openMobile = false;
	let openProfile = false;

	function openNavMenu() {
		openMobile = !openMobile;
	}

	function openProfileMenu() {
		openProfile = !openProfile;
	}
</script>

<nav class="shadow-md">
	<div class="container mx-auto px-2 sm:px-6 lg:px-8">
		<div class="relative flex items-center justify-between h-16">
			<div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
				<!-- Mobile menu button-->
				<button
					type="button"
					class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
					aria-controls="mobile-menu"
					aria-expanded="false"
					on:click={openNavMenu}
				>
					<span class="sr-only">Open main menu</span>

					<!--
              Menu open: "hidden", Menu closed: "block"
            -->
					<svg
						class={`${openMobile ? "hidden" : "block"} h-6 w-6`}
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						aria-hidden="true"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M4 6h16M4 12h16M4 18h16"
						/>
					</svg>
					<!--
              Menu open: "block", Menu closed: "hidden"
            -->
					<svg
						class={`${openMobile ? "block" : "hidden"} h-6 w-6`}
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						aria-hidden="true"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
			</div>

			<div
				class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-between"
			>
				<div
					class="flex-shrink-0 flex items-center items-center"
					on:click={() => navigate("/", { replace: true })}
				>
					<span class=" text-xl text-gray-900 cursor-pointer">KYLE SCOTT</span>
					<img
						class="block h-8 w-auto cursor-pointer ml-2"
						src={"/assets/images/kyle-scott-w-icon.svg"}
						alt="Kylescottw Icon"
					/>
				</div>
				<div class="hidden sm:block sm:ml-6">
					<div class="flex space-x-4">
						<!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->

						<div
							on:click={() => navigate("/about", { replace: true })}
							class="cursor-pointer"
						>
							<span
								class="text-gray-600  px-3 py-2 rounded-md text-sm font-medium no-underline hover:no-underline"
							>
								About
							</span>
						</div>

						<div
							on:click={() => navigate("/articles", { replace: true })}
							class="cursor-pointer"
						>
							<span
								class="text-gray-600  px-3 py-2 rounded-md text-sm font-medium no-underline hover:no-underline"
							>
								Articles
							</span>
						</div>
						<div
							on:click={() => navigate("/companies", { replace: true })}
							class="cursor-pointer"
						>
							<span
								class="text-gray-600  px-3 py-2 rounded-md text-sm font-medium no-underline hover:no-underline"
							>
								Companies
							</span>
						</div>

						<!-- <a
              href="/contact"
              class="text-gray-600  px-3 py-2 rounded-md text-sm font-medium no-underline hover:no-underline"
              >Contact</a
            > -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Mobile menu, show/hide based on menu state. -->
	{#if openMobile}
		<div
			class={`sm:hidden mobileOverlay ${
				openMobile ? "transform opacity-100" : "transform opacity-0 hidden "
			}`}
			id="mobile-menu"
			in:fly={{ y: 0, duration: 2000 }}
		>
			<div class="px-2 pt-2 pb-3 space-y-1">
				<!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->

				<div
					on:click={() => navigate("/about", { replace: true })}
					class="cursor-pointer"
				>
					<span
						class="text-gray-600 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:no-underline"
					>
						About
					</span>
				</div>

				<div
					on:click={() => navigate("/articles", { replace: true })}
					class="cursor-pointer"
				>
					<span
						class="text-gray-600 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:no-underline"
					>
						Articles
					</span>
				</div>
				<div
					on:click={() => navigate("/companies", { replace: true })}
					class="cursor-pointer"
				>
					<span
						class="text-gray-600 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:no-underline"
					>
						Companies
					</span>
				</div>

				<!-- <a
          href="#"
          class="text-gray-600 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:no-underline"
          >Contact</a
        > -->
			</div>
		</div>
	{/if}
</nav>

<style>
	.mobileOverlay {
		position: absolute;
		width: 100%;
		z-index: 999;
		background: white;
	}
</style>
