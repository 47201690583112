<script>
  import { fade } from "svelte/transition";
</script>

<div class="flex flex-wrap mt-16 mb-4 ">
  <div class="w-full lg:w-1/2   about--image-border">
    <img
      src="/assets/images/kyle-scott-w-bw.jpg"
      alt="Kyle Wiggins Portrait Photograph"
      class="w-40 m-auto circle-img mb-8 mt-6 lg:mb-16 lg:mt-20"
      in:fade={{ duration: 1500 }}
    />
  </div>
  <div class="w-full lg:w-1/2 p-8">
    <h1 class="text-2xl font-medium text-gray-800 subpixel-antialiased">
      Kyle Scott Wiggins
    </h1>
    <p class="text-gray-600 subpixel-antialiased text-sm">
      Developer | Designer | Entrepreneur
    </p>
    <p class="mt-4 text-gray-700 subpixel-antialiased leading-loose">
      A professional web developer well versed in modern JavaScript development,
      tooling and frameworks. An extensive nine-year background in digital
      design and development experience across a multitude of product life
      cycles spanning from conceptualization to execution. Always striving to
      create optimal software products emphasizing a pristine user experience
      whilst meeting a higher standard for design and aesthetics.
    </p>
    <a href="https://www.linkedin.com/in/kylescottw/" target="_blank"
      ><img
        src="/static/about/linkedin.svg"
        alt=""
        class="w-6 mt-6 inline-block mr-6"
      /></a
    ><a href="https://github.com/Kylescottw" target="_blank"
      ><img
        src="/static/about/github.svg"
        alt=""
        class="w-6 mt-6 inline-block"
      /></a
    >
  </div>
</div>

<style>
  .circle-img {
    border-radius: 50%;
  }

  .about--image-border {
    border-right: 2px solid #e8e8e8;
    border-bottom: none;
  }
</style>
